import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://41429b7a95c3c5262de1ed1a7ed08637@o4504139493015552.ingest.sentry.io/4506149634834432',
	tracesSampleRate: 1.0,
	environment: import.meta.env.MODE
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
